import { Injectable } from '@angular/core';
import { VariationType } from '../domain/models/ab-test-options.model';
import { AbTestStorageService } from './base-storage.service';

const STORAGE_NAMESPACE = 'rma-ab';

@Injectable()
export class LocalStorageService implements AbTestStorageService {
  public get(name: string): VariationType | undefined {
    if (!localStorage) {
      return undefined;
    }

    const experiments = this.getAll();

    return experiments[name] ?? undefined;
  }

  public set(name: string, value: string, _expires?: number) {
    if (localStorage) {
      const current = this.getAll();
      const updated = { ...current, [name]: value };
      localStorage.setItem(STORAGE_NAMESPACE, JSON.stringify(updated));
    }
  }

  private getAll(): Record<string, VariationType> {
    return JSON.parse(localStorage.getItem(STORAGE_NAMESPACE) ?? '{}');
  }
}
